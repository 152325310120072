import * as React from 'react'
import PageView from '../views/pageView'
import data from '../data/index.json'
import Seo from '../components/seo'

export default function Index() {
  return <PageView data={data} />
}

export const Head = () => <Seo />
